import React from "react";
import { ActivityType } from "enums/activityType";
import moment from "moment";
import { middleEllipsis } from "./helpers";
import { VaultRoleEnum } from "enums/vaultRole";

export function getActivityDetail(activity, addInEntityType = false) {
  const actingUserShortName = `${
    activity.actingUser?.userProfileFirstName?.split(" ")?.[0]
  } ${activity.actingUser?.userProfileLastName?.split(" ")?.[0]}`;
  const header = `${activity.actingUser?.userProfileFirstName} ${activity.actingUser?.userProfileLastName}`;
  const date = moment(parseInt(activity.timestamp)).format("M/D/YYYY @ h:mm A");
  const ipAddress = activity.ipAddress;
  const clientName = activity.data?.clientName;
  let entityType = "";
  let action = "";
  let section = "";

  switch (activity.data?.sectionName) {
    case "Person":
      section = "People";
      break;
    case "Thing":
      section = "Things";
      break;
    case "Document":
      section = "Documents";
      break;
    case "Place":
      section = "Places";
      break;
    default:
      section = activity.data?.sectionName;
      break;
  }

  switch (activity.data?.entityType) {
    case "PERSON":
      entityType = "People";
      break;
    case "THING":
      entityType = "Things";
      break;
    case "PLACE":
      entityType = "Places";
      break;
    case "DOCUMENT":
      entityType = "Documents";
      break;
    default:
      entityType = activity.data?.entityType;
      break;
  }

  switch (activity?.activityType) {
    // Person Activities
    case ActivityType.PERSON_VIEWED:
      action = (
        <>
          Viewed{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.personFirstName} {activity?.data?.personLastName}
          </span>
          {addInEntityType && " in People"}
        </>
      );
      break;
    case ActivityType.PERSON_ADDED:
      action = (
        <>
          Added{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.personFirstName} {activity?.data?.personLastName}
          </span>
          {addInEntityType && " in People"}
        </>
      );
      break;
    case ActivityType.PERSON_UPDATED:
      action = (
        <>
          Edited details about{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.personFirstName} {activity?.data?.personLastName}
          </span>
          {addInEntityType && " in People"}
        </>
      );
      break;
    case ActivityType.PERSON_DELETED:
      action = (
        <>
          Deleted{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.personFirstName} {activity?.data?.personLastName}
          </span>
          {addInEntityType && " in People"}
        </>
      );
      break;
    case ActivityType.IMAGE_ADDED_TO_PERSON:
      action = (
        <>
          Added a Photo to{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.personName}</span>
          {addInEntityType && " in People"}
        </>
      );
      break;
    case ActivityType.IMAGE_DELETED_FROM_PERSON:
      action = (
        <>
          Removed a photo from{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.personName}</span>
          {addInEntityType && " in People"}
        </>
      );
      break;
    case ActivityType.PERSON_INVITED_TO_VAULT:
      action = (
        <>
          Invited{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.personFirstName} {activity?.data?.personLastName}
          </span>{" "}
          to this vault{" "}
          {activity?.data?.vaultRole === VaultRoleEnum.ROLE_VAULT_CO_OWNER &&
            `as a Co-Owner`}
        </>
      );
      break;
    case ActivityType.PERSON_INVITE_REMOVED_FROM_VAULT:
      action = (
        <>
          Removed{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.personFirstName} {activity?.data?.personLastName}
          </span>{" "}
          from this vault
        </>
      );
      break;
    case ActivityType.PERSON_ACCEPTED_INVITE_TO_VAULT:
      action = (
        <>
          Accepted invite
          {addInEntityType && (
            <>
              {" from "}
              <span style={{ fontWeight: 700 }}>
                {activity?.data?.personFirstName}{" "}
                {activity?.data?.personLastName}
              </span>{" "}
            </>
          )}
        </>
      );
      break;
    case ActivityType.PERSON_INVITE_RESENT:
      action = (
        <>
          Resent an invite to
          <span style={{ fontWeight: 700 }}>
            &nbsp;{activity?.data?.personFirstName}&nbsp;
            {activity?.data?.personLastName}&nbsp;
          </span>
        </>
      );
      break;
    case ActivityType.PERSON_INVITE_CANCELLED:
      action = (
        <>
          Cancelled an invite to
          <span style={{ fontWeight: 700 }}>
            &nbsp;{activity?.data?.personFirstName}&nbsp;
            {activity?.data?.personLastName}&nbsp;
          </span>
        </>
      );
      break;

    case ActivityType.PERSON_ROLE_CHANGED:
      action = (
        <>
          Updated
          <span style={{ fontWeight: 700 }}>
            &nbsp;{activity?.data?.personFirstName}&nbsp;
            {activity?.data?.personLastName}'s
          </span>{" "}
          role to{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.vaultRole === VaultRoleEnum.ROLE_VAULT_CO_OWNER &&
              "Co-Owner"}
            {activity?.data?.vaultRole ===
              VaultRoleEnum.ROLE_INVITED_USER_ENUM && "invited user"}
          </span>
        </>
      );
      break;

    // Document Activities
    case ActivityType.DOCUMENT_ADDED:
      action = (
        <>
          Added{" "}
          <span style={{ fontWeight: 700 }}>
            {middleEllipsis(activity?.data?.documentTitle, 30, 15, 15)}
          </span>
          {addInEntityType && " in Documents"}
        </>
      );
      break;
    case ActivityType.DOCUMENT_VIEWED:
      action = (
        <>
          Viewed{" "}
          <span style={{ fontWeight: 700 }}>
            {middleEllipsis(activity?.data?.documentTitle, 30, 15, 15)}
          </span>
          {addInEntityType && " in Documents"}
        </>
      );
      break;
    case ActivityType.DOCUMENT_UPDATED:
      action = (
        <>
          Edited details about{" "}
          <span style={{ fontWeight: 700 }}>
            {middleEllipsis(activity?.data?.documentTitle, 30, 15, 15)}
          </span>
          {addInEntityType && " in Documents"}
        </>
      );
      break;
    case ActivityType.DOCUMENT_DOWNLOADED:
      action = (
        <>
          Downloaded{" "}
          <span style={{ fontWeight: 700 }}>
            {middleEllipsis(activity?.data?.documentTitle, 30, 15, 15)}
          </span>
          {addInEntityType && " in Documents"}
        </>
      );
      break;
    case ActivityType.DOCUMENT_DELETED:
      action = (
        <>
          Deleted{" "}
          <span style={{ fontWeight: 700 }}>
            {middleEllipsis(activity?.data?.documentTitle, 30, 15, 15)}
          </span>
          {addInEntityType && " in Documents"}
        </>
      );
      break;

    // Thing Activities
    case ActivityType.THING_VIEWED:
      action = (
        <>
          Viewed{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.thingName}</span>
          {addInEntityType && " in Things"}
        </>
      );
      break;
    case ActivityType.THING_ADDED:
      action = (
        <>
          Added{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.thingName}</span>
          {addInEntityType && " in Things"}
        </>
      );
      break;
    case ActivityType.THING_UPDATED:
      action = (
        <>
          Edited details about{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.thingName}</span>
          {addInEntityType && " in Things"}
        </>
      );
      break;
    case ActivityType.THING_DELETED:
      action = (
        <>
          Deleted{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.thingName}</span>
          {addInEntityType && " in Things"}
        </>
      );
      break;
    case ActivityType.IMAGE_ADDED_TO_THING:
      action = (
        <>
          Added Image to{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.thingName}</span>
          {addInEntityType && " in Things"}
        </>
      );
      break;
    case ActivityType.IMAGE_DELETED_FROM_THING:
      action = (
        <>
          Deleted Image from{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.thingName}</span>
          {addInEntityType && " in Things"}
        </>
      );
      break;
    //Access Activities
    case ActivityType.SHARE_PERMISSION_ADDED:
      action = (
        <>
          Shared{" "}
          <>
            <span style={{ fontWeight: 700 }}>{activity?.data?.itemName}</span>
            {addInEntityType && (
              <>
                {" in "}
                <span style={{ fontWeight: 700 }}>{section}</span>
              </>
            )}
            {" with "}
            <span style={{ fontWeight: 700 }}>
              {activity?.data?.sharedWithName}
            </span>
          </>
        </>
      );
      break;
    case ActivityType.SHARE_PERMISSION_REMOVED:
      action = (
        <>
          Stopped sharing{" "}
          <>
            <span style={{ fontWeight: 700 }}>{activity?.data?.itemName}</span>
            {addInEntityType && (
              <>
                {" in "}
                <span style={{ fontWeight: 700 }}>{section}</span>
              </>
            )}
            {" with "}
            <span style={{ fontWeight: 700 }}>
              {`${activity?.data?.sharedWithName}`}
            </span>
          </>
        </>
      );
      break;
    //Media Activities
    case ActivityType.MEDIA_ADDED:
      action = (
        <>
          Added the {activity?.data?.mediaFamily}
          <>
            <span
              style={{ fontWeight: 700 }}
            >{` ${activity?.data?.fileName}`}</span>
            {addInEntityType && (
              <>
                {" in "}
                <span>{entityType}</span>
              </>
            )}
          </>
        </>
      );
      break;
    case ActivityType.MEDIA_DOWNLOADED:
      action = (
        <>
          Downloaded{" "}
          {activity?.data?.mediaFamily === "image"
            ? "photo"
            : activity?.data?.mediaFamily}
          <>
            <span
              style={{ fontWeight: 700 }}
            >{` ${activity?.data?.fileName}`}</span>
            {addInEntityType && (
              <>
                {" in "}
                <span>{entityType}</span>
              </>
            )}
          </>
        </>
      );
      break;
    case ActivityType.MEDIA_DELETED:
      action = (
        <>
          Deleted the {activity?.data?.mediaFamily}
          <>
            <span
              style={{ fontWeight: 700 }}
            >{` ${activity?.data?.fileName}`}</span>
            {addInEntityType && (
              <>
                {" in "}
                <span>{entityType}</span>
              </>
            )}
          </>
        </>
      );
      break;
    case ActivityType.MEDIA_VIDEO_WATCHED:
      action = (
        <>
          Watched the video
          <>
            <span
              style={{ fontWeight: 700 }}
            >{` ${activity?.data?.fileName}`}</span>
            {addInEntityType && (
              <>
                {" in "}
                <span>{entityType}</span>
              </>
            )}
          </>
        </>
      );
      break;
    case ActivityType.MEDIA_UPDATED:
      action = (
        <>
          Updated the {activity?.data?.mediaFamily}
          <>
            <span
              style={{ fontWeight: 700 }}
            >{` ${activity?.data?.fileName}`}</span>
            {addInEntityType && (
              <>
                {" in "}
                <span>{entityType}</span>
              </>
            )}
          </>
        </>
      );
      break;
    // Note Activities
    case ActivityType.NOTE_ADDED:
      action = (
        <>
          Added a note
          {addInEntityType && ` in ${entityType}`}
        </>
      );
      break;
    case ActivityType.NOTE_UPDATED:
      action = (
        <>
          Edited a note
          {addInEntityType && ` in ${entityType}`}
        </>
      );
      break;
    case ActivityType.NOTE_DELETED:
      action = (
        <>
          Deleted a note
          {addInEntityType && ` in ${entityType}`}
        </>
      );
      break;
    // Place Activities
    case ActivityType.PLACE_VIEWED:
      action = (
        <>
          Viewed{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.placeName}</span>
          {addInEntityType && " in Places"}
        </>
      );
      break;
    case ActivityType.PLACE_ADDED:
      action = (
        <>
          Added{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.placeName}</span>
          {addInEntityType && " in Places"}
        </>
      );
      break;
    case ActivityType.PLACE_UPDATED:
      action = (
        <>
          Edited details about{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.placeName}</span>
          {addInEntityType && " in Places"}
        </>
      );
      break;
    case ActivityType.PLACE_DELETED:
      action = (
        <>
          Deleted{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.placeName}</span>
          {addInEntityType && " in Places"}
        </>
      );
      break;
    case ActivityType.IMAGE_ADDED_TO_PLACE:
      action = (
        <>
          Added Image to{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.placeName}</span>
          {addInEntityType && " in Places"}
        </>
      );
      break;
    case ActivityType.IMAGE_DELETED_FROM_PLACE:
      action = (
        <>
          Deleted Image from{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.placeName}</span>
          {addInEntityType && " in Places"}
        </>
      );
      break;
    case ActivityType.USER_TERMS_ACCEPTED:
      action = (
        <>
          Accepted{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.userTermsAcceptedActivityType}
          </span>
        </>
      );
      break;
    case ActivityType.USER_LOGIN_SUCCESSFUL:
      action = (
        <>
          Logged in{" "}
          {(activity?.data?.clientName || activity?.loginDevice) && (
            <span style={{ fontWeight: 700 }}>
              to {activity?.data?.clientName ?? activity?.loginDevice}
            </span>
          )}
        </>
      );
      break;
    case ActivityType.BIOMETRIC_LOGIN_SUCCESSFUL:
      action = (
        <>
          Logged in{" "}
          {(activity?.data?.clientName || activity?.loginDevice) && (
            <span style={{ fontWeight: 700 }}>
              to {activity?.data?.clientName ?? activity?.loginDevice} using
              Biometrics
            </span>
          )}
        </>
      );
      break;
    case ActivityType.VAULT_NAME_UPDATED:
      action = <>Edited this vault's name</>;
      break;
    case ActivityType.VAULT_ACCESSED:
      action = <>Accessed this vault</>;
      break;
    case ActivityType.USER_PROFILE_UPDATED:
      action = <>Profile Updated</>;
      break;
    case ActivityType.INVOICE_PAID:
      action = <>Invoice Paid</>;
      break;
    case ActivityType.USER_PROFILE_CREATED:
      action = <>User Profile Created</>;
      break;
    case ActivityType.USER_PASSWORD_CHANGE_SUCCESSFUL:
      action = <>Password Updated</>;
      break;
    case ActivityType.VAULT_VIEWED:
      action = <>Accessed this vault</>;
      break;
    case ActivityType.ASSET_LINK_ADDED:
      action = (
        <>
          Added{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.linkToName}</span>{" "}
          as a related item to{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.linkFromName}
          </span>
          {addInEntityType && ` in ${entityType}`}
        </>
      );
      break;
    case ActivityType.ASSET_LINK_REMOVED:
      action = (
        <>
          Removed{" "}
          <span style={{ fontWeight: 700 }}>{activity?.data?.linkToName}</span>{" "}
          as a related item from{" "}
          <span style={{ fontWeight: 700 }}>
            {activity?.data?.linkFromName}
          </span>
          {addInEntityType && ` in ${entityType}`}
        </>
      );
      break;
    default:
      console.log(`No Activity Builder for ${activity?.activityType}`);
      break;
  }

  return { actingUserShortName, header, action, date, ipAddress, clientName };
}
