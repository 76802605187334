import React from "react";
import List from "components/shared/list/List";
import { history } from "util/router";
import moment from "moment";

export default function Agent({ agent }) {
  return (
    <List.Row
      data-testid={`agent-${agent?.userId}`}
      onClick={() => history.push(`/agents/${agent?.userId}`)}
    >
      <List.Text title={`${agent?.name}`} shrinkPadding />
      <List.Text title={agent?.userId} increasePadding />
      <List.Text
        title={moment(parseInt(agent?.lastLoginTime) * 1000).format(
          "M/D/YYYY @ h:mm A"
        )}
      />
    </List.Row>
  );
}
