import React, { useState, useMemo } from "react";
import List from "components/shared/list/List";
import styles from "./AgentActivities.module.scss";
import cx from "classnames";
import NoContent from "components/shared/nocontent/NoContent";
import Filter from "components/shared/filter/Filter";
import FormField from "components/shared/formfield/FormField";
import moment from "moment";
import { useDebounce } from "hooks/useDebounce";
import NoResults from "components/shared/noresults/NoResults";
import ActivityDataModal from "./modals/ActivityDataModal";
import { useAgentActivities } from "stores/supportStore";
import agentActivityTypes from "enums/agentActivityTypes";
import { agentActivityBelongsToFilter } from "util/agentActivityFilter";
import { getAgentActivityDetail } from "util/agentActivityBuilder";
import PageLoader from "components/shared/pageloader/PageLoader";

export default function AgentActivities({ agentId }) {
  const [showData, setShowData] = useState(false);
  const [activeActivity, setActiveActivity] = useState();

  //Filter
  const [search, setSearch] = useState("");
  const [activeDateFilter, setActiveDateFilter] = useState();
  const [activeFilter, setActiveFilter] = useState();
  const [filterDate, setFilterDate] = useState("All Time");
  const [filterDateBy, setFilterDateBy] = useState(null);
  const [filterTitle, setFilterTitle] = useState("All Activities");
  const [filterBy, setFilterBy] = useState("");
  const debouncedSearchTerm = useDebounce(search, 300);

  const { data: activities, isLoading } = useAgentActivities(agentId);

  function filterByDate(activity) {
    let f = moment().subtract(filterDateBy, "days").unix();
    let ts = moment(parseInt(activity?.timestamp)).unix();
    if (filterDateBy === null) return true;
    return ts > f;
  }

  //Set our debouncedSearchTerm
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  //Memoization of activities and filters
  const filteredActivities = useMemo(() => {
    return activities?.data
      ?.filter((activity) =>
        activity?.data?.activityDescription
          .toLowerCase()
          .includes(debouncedSearchTerm.toLowerCase())
      )
      ?.filter(filterByDate)
      ?.filter((activity) => agentActivityBelongsToFilter(activity, filterBy));
  }, [activities, debouncedSearchTerm, filterBy, filterDateBy]);

  //Filter
  function onFilterItemClick(name, filter) {
    setFilterTitle(name);
    setFilterBy(filter);
  }
  function onFilterDateClick(name, filter) {
    setFilterDate(name);
    setFilterDateBy(filter);
  }

  return (
    <>
      <div data-testid="agent-activities" className="container mt-2">
        <div className={cx("content", styles.content)}>
          <div className="columns">
            <div className="column">
              {isLoading ? (
                <PageLoader data-testid="agent-activities-loader" />
              ) : activities?.data?.length > 0 ? (
                <div className={styles.list}>
                  <List>
                    <List.SearchRow>
                      <List.SearchInput>
                        <FormField
                          name="search"
                          type="text"
                          size="small"
                          placeholder="Search"
                          onChange={handleSearchChange}
                          autoComplete="off"
                          hasMargin={false}
                          id="search-input"
                        />
                      </List.SearchInput>
                      <List.SearchFilters>
                        <Filter
                          title={filterDate}
                          filterRef="filterDateBy"
                          onClick={() => {}}
                          active={activeDateFilter === "filterDateBy"}
                          id="filter-date-by-dropdown"
                          data-testid="agent-activities-filter-by-date-dropdown"
                        >
                          <Filter.FilterItem
                            data-testid="agent-activities-filter-by-all-time"
                            id="filter-by-all-time"
                            name="All Time"
                            onClick={() => onFilterDateClick("All Time", null)}
                          />
                          <Filter.FilterItem
                            data-testid="agent-activities-filter-by-90-days"
                            id="filter-by-90-days"
                            name="Last 90 Days"
                            onClick={() =>
                              onFilterDateClick("Last 90 Days", 90)
                            }
                          />
                          <Filter.FilterItem
                            data-testid="agent-activities-filter-by-30-days"
                            id="filter-by-30-days"
                            name="Last 30 Days"
                            onClick={() =>
                              onFilterDateClick("Last 30 Days", 30)
                            }
                          />
                          <Filter.FilterItem
                            data-testid="agent-activities-filter-by-7-days"
                            id="filter-by-7-days"
                            name="Last 7 Days"
                            onClick={() => onFilterDateClick("Last 7 Days", 7)}
                          />
                          <Filter.FilterItem
                            data-testid="agent-activities-filter-by-24-hours"
                            id="filter-by-24-hours"
                            name="Last 24 Hours"
                            onClick={() =>
                              onFilterDateClick("Last 24 Hours", 1)
                            }
                          />
                        </Filter>
                        <Filter
                          title={filterTitle}
                          filterRef="filterBy"
                          onClick={() => {}}
                          active={activeFilter === "filterBy"}
                          id="filter-by-dropdown"
                        >
                          <Filter.FilterItem
                            id="filter-by-all-activities"
                            name="All Activities"
                            onClick={() =>
                              onFilterItemClick(
                                "All Activities",
                                agentActivityTypes.ALL
                              )
                            }
                            data-testid="agent-activities-filter-by-all-activities"
                          />
                          <hr className="mt-1 mb-1" />
                          <Filter.FilterItem
                            id="filter-by-agent"
                            name="Agent"
                            onClick={() =>
                              onFilterItemClick(
                                "Agent",
                                agentActivityTypes.AGENT
                              )
                            }
                            data-testid="agent-activities-filter-by-agent"
                          />
                          <Filter.FilterItem
                            id="filter-by-user"
                            name="User"
                            onClick={() =>
                              onFilterItemClick("User", agentActivityTypes.USER)
                            }
                            data-testid="agent-activities-filter-by-user"
                          />
                          <Filter.FilterItem
                            id="filter-by-vault"
                            name="Vault"
                            onClick={() =>
                              onFilterItemClick(
                                "Vault",
                                agentActivityTypes.VAULT
                              )
                            }
                            data-testid="agent-activities-filter-by-vault"
                          />
                        </Filter>
                      </List.SearchFilters>
                    </List.SearchRow>
                    {filteredActivities?.map((activity, index) => {
                      const activityDetail = getAgentActivityDetail(activity);
                      return (
                        <List.Row
                          data-testid={`agent-activity-${activity.activityId}`}
                          key={activity.activityId}
                          onClick={() => {
                            setActiveActivity(activity);
                            setShowData(true);
                          }}
                        >
                          <List.Text
                            title={activityDetail.header}
                            subtitle={activityDetail.action}
                          />
                          <span className={styles.date}>
                            {activityDetail.date}
                          </span>
                        </List.Row>
                      );
                    })}
                    {filteredActivities?.length === 0 && (
                      <NoResults
                        subtitle="There are no activities that match the filters applied. Please try again."
                        size="large"
                      />
                    )}
                  </List>
                </div>
              ) : (
                <NoContent
                  title="No Recent Activity"
                  subtitle="As you use your Vault, the most recent activities will appear here."
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showData && (
        <div data-testid="agent-activity-detail-modal">
          <ActivityDataModal
            activity={activeActivity}
            onCancel={() => setShowData(false)}
          />
        </div>
      )}
    </>
  );
}
