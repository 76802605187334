import React from "react";
import PropTypes from "prop-types";
import TextLink from "components/shared/link/TextLink";
import { middleEllipsis } from "util/helpers";
import Icon from "components/shared/icon/Icon";
import CopyToClipboard from "components/shared/copytoclipboard/CopyToClipboard";

function Info({ infoArray }) {
  return (
    <div className="columns is-multiline mb-3">
      {infoArray?.map((info, index) => {
        return (
          <div
            className={`field pb-3 column ${
              info?.isFullCol ? "is-full" : "is-half"
            }`}
            key={`${info}${index}`}
          >
            <label className="label has-text-size-normal">
              {info.label}
              {info?.canCopy && <CopyToClipboard textToCopy={info?.value} />}
            </label>

            {info?.isLink ? (
              <TextLink
                url={info?.value}
                text={middleEllipsis(info?.value, 30, 15, 15)}
                id={`info-link`}
                openNewWindow
              />
            ) : (
              <p
                className={`has-text-karman100 has-text-weight-medium ${info?.color}`}
              >
                {!!info.value ? info.value : "--"}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
}

Info.propTypes = {};

export default Info;
