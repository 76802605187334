import React, { useState, useRef, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./User.module.scss";
import Panel from "components/shared/panel/Panel";
import { useUser, useUserVaults } from "stores/supportStore";
import PageLoader from "components/shared/pageloader/PageLoader";
import Tabs from "components/shared/tabs/Tabs";
import BackButton from "components/shared/button/BackButton";
import cx from "classnames";
import Dropdown from "components/shared/dropdown/Dropdown";
import UserAccountInfo from "./accountinfo/UserAccountInfo";
import UserVaults from "./vaults/UserVaults";
import UserLockModal from "./modals/UserLockModal";
import UserUnlockModal from "./modals/UserUnlockModal";
import UserSendCodeModal from "./modals/UserSendCodeModal";
import FloatingActionButton from "components/shared/floatingactionbutton/FloatingActionButton";
import UserSupportActivity from "./activity/UserSupportActivity";
import UserDataModal from "./modals/UserDataModal";
import UserActivity from "./activity/UserActivity";
import UserVerifyCodeModal from "./modals/UserVerifyCodeModal";
import UserResetPasswordModal from "./modals/UserResetPasswordModal";
import EndUserSessionModal from "./modals/EndUserSessionModal";
import CustomerSupportPermissionValidator from "components/shared/permissions/CustomerSupportPermissionValidator";
import customerSupportPermissions from "enums/permissions/customerSupportPermissions";

export default function User({ userProfileId, section }) {
  const [isActive, setIsActive] = useState(true);
  const { logout } = useAuth0();

  const [isLockingUser, setIsLockingUser] = useState(false);
  const [isUnlockingUser, setIsUnlockingUser] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [showData, setShowData] = useState(false);
  const [isEndingSession, setIsEndingSession] = useState(false);

  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
  } = useUser(userProfileId);
  const {
    data: vaults,
    error: vaultsError,
    isLoading: isVaultsLoading,
  } = useUserVaults(userProfileId);
  const userDropdownRef = useRef(null);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  useEffect(() => {
    if (!user) return;
    setIsActive(user?.data?.userProfile?.userStatus === "ACTIVE");
  }, [user]);

  return (
    <>
      {isUserLoading ? (
        <PageLoader />
      ) : (
        <div className={styles.userContainer}>
          <div className={styles.user}>
            <BackButton link={`/users`} />
            <div className={cx("container", styles.container)}>
              <Panel>
                <article className="tile notification is-white is-justify-content-center mb-0 pb-3">
                  <div className="is-flex is-flex-direction-column has-text-centered px-3">
                    {/* <div className="is-flex is-flex-direction-row is-justify-content-center">
                      <div className={styles.status}>
                        <p>{user?.data?.userProfile?.userStatus}</p>
                      </div>
                    </div> */}
                    <h3 className="mb-1 mt-3 has-text-karman100" id="user-name">
                      {user?.data?.userProfile?.firstName}{" "}
                      {user?.data?.userProfile?.lastName}
                    </h3>
                    <p className="pt-0 mt-0 has-text-karman50" id="user-email">
                      {user?.data?.userProfile?.email}
                    </p>
                  </div>
                </article>
                <div className={styles.dropdownMenu}>
                  <span ref={userDropdownRef} className="is-hidden"></span>
                  <Dropdown id="dropdown-user-menu-btn">
                    <CustomerSupportPermissionValidator
                      permissions={[
                        customerSupportPermissions.USER_LOCK_PERMISSION,
                      ]}
                    >
                      <Dropdown.Item
                        text="Lock User"
                        icon="lock"
                        onClick={() => {
                          userDropdownRef.current.click();
                          setIsLockingUser(true);
                        }}
                        color="danger"
                        id="dropdown-lock-user-btn"
                      />
                    </CustomerSupportPermissionValidator>
                    <CustomerSupportPermissionValidator
                      permissions={[
                        customerSupportPermissions.USER_UNLOCK_PERMISSION,
                      ]}
                    >
                      <Dropdown.Item
                        text="Unlock User"
                        icon="lock_open"
                        onClick={() => {
                          userDropdownRef.current.click();
                          setIsUnlockingUser(true);
                        }}
                        id="dropdown-unlock-user-btn"
                      />
                    </CustomerSupportPermissionValidator>
                    <CustomerSupportPermissionValidator
                      permissions={[
                        customerSupportPermissions.USER_PASSWORD_RESET_PERMISSION,
                      ]}
                    >
                      <Dropdown.Item
                        text="Reset Password"
                        icon="password"
                        onClick={() => {
                          userDropdownRef.current.click();
                          setIsResettingPassword(true);
                        }}
                        id="dropdown-reset-pw-btn"
                      />
                    </CustomerSupportPermissionValidator>
                    <CustomerSupportPermissionValidator
                      permissions={[
                        customerSupportPermissions.USER_SEND_CODE_PERMISSION,
                      ]}
                    >
                      <Dropdown.Item
                        text="Send Code"
                        icon="chat"
                        onClick={() => {
                          userDropdownRef.current.click();
                          setIsSendingCode(true);
                        }}
                        id="dropdown-send-code-btn"
                      />
                    </CustomerSupportPermissionValidator>
                    <CustomerSupportPermissionValidator
                      permissions={[
                        customerSupportPermissions.USER_SEND_CODE_PERMISSION,
                      ]}
                    >
                      <Dropdown.Item
                        text="Verify Code"
                        icon="check_box"
                        onClick={() => {
                          userDropdownRef.current.click();
                          setIsVerifyingCode(true);
                        }}
                        id="dropdown-verify-code-btn"
                      />
                    </CustomerSupportPermissionValidator>
                    <Dropdown.Item
                      text="View API Data"
                      icon="data_object"
                      onClick={() => {
                        setShowData(true);
                      }}
                      id="show-user-data-btn"
                    />
                  </Dropdown>
                </div>

                <Tabs isCentered>
                  <Tabs.Item
                    isWide
                    url={`/users/${userProfileId}`}
                    text="Account Info"
                  />
                  <Tabs.Item
                    isWide
                    url={`/users/${userProfileId}/vaults`}
                    text={`Vaults (${vaults?.data?.length || 0})`}
                  />
                  {/* <Tabs.Item
                    isWide
                    url={`/users/${userProfileId}/support`}
                    text="Support Activity"
                  /> */}
                  <Tabs.Item
                    isWide
                    url={`/users/${userProfileId}/activity`}
                    text="User Activity"
                  />
                </Tabs>
              </Panel>

              {section === undefined && (
                <UserAccountInfo
                  user={user?.data}
                  vault={vaults?.data?.[0]}
                  activities={user?.data?.userActivities}
                />
              )}
              {section === "vaults" && (
                <UserVaults user={user?.data} vaults={vaults} />
              )}
              {section === "support" && (
                <UserSupportActivity user={user?.data} />
              )}

              {section === "activity" && (
                <UserActivity activities={user?.data?.userActivities} />
              )}
            </div>
          </div>
        </div>
      )}

      <FloatingActionButton>
        <CustomerSupportPermissionValidator
          permissions={[customerSupportPermissions.USER_LOCK_PERMISSION]}
          additionalCheck={isActive}
        >
          <FloatingActionButton.Button
            text="Lock user"
            icon="lock"
            type="dangerInverse"
            id="floating-action-button-lock-user-btn"
            onClick={() => {
              setIsLockingUser(true);
            }}
          />
        </CustomerSupportPermissionValidator>
        <CustomerSupportPermissionValidator
          permissions={[customerSupportPermissions.USER_UNLOCK_PERMISSION]}
          additionalCheck={!isActive}
        >
          <FloatingActionButton.Button
            text="Unlock user"
            icon="lock_open"
            type="secondary"
            id="floating-action-button-unlock-user-btn"
            onClick={() => {
              setIsUnlockingUser(true);
            }}
          />
        </CustomerSupportPermissionValidator>
        <CustomerSupportPermissionValidator
          permissions={[customerSupportPermissions.USER_SEND_CODE_PERMISSION]}
          additionalCheck={!codeSent}
        >
          <FloatingActionButton.Button
            text="Send Code"
            icon="chat"
            type="secondary"
            id="floating-action-button-send-code-btn"
            onClick={() => {
              setIsSendingCode(true);
            }}
          />
        </CustomerSupportPermissionValidator>
        <CustomerSupportPermissionValidator
          permissions={[customerSupportPermissions.USER_SEND_CODE_PERMISSION]}
          additionalCheck={codeSent}
        >
          <FloatingActionButton.Button
            text="Verify Code"
            icon="check_box"
            type="secondary"
            id="floating-action-button-verify-code-btn"
            onClick={() => {
              setIsVerifyingCode(true);
            }}
          />
        </CustomerSupportPermissionValidator>
        <CustomerSupportPermissionValidator
          permissions={[customerSupportPermissions.USER_SEND_CODE_PERMISSION]}
          additionalCheck={!isEndingSession}
        >
          <FloatingActionButton.Button
            text="End Session"
            icon="close"
            type="secondary"
            id="floating-action-button-end-session-btn"
            onClick={() => {
              setIsEndingSession(true);
            }}
          />
        </CustomerSupportPermissionValidator>
      </FloatingActionButton>
      {isLockingUser && (
        <UserLockModal
          onCancel={() => setIsLockingUser(false)}
          onDone={() => setIsLockingUser(false)}
          user={user?.data}
        />
      )}
      {isUnlockingUser && (
        <UserUnlockModal
          user={user?.data}
          onCancel={() => setIsUnlockingUser(false)}
          onDone={() => setIsUnlockingUser(false)}
        />
      )}
      {isSendingCode && (
        <UserSendCodeModal
          onCancel={() => setIsSendingCode(false)}
          onDone={() => {
            setIsSendingCode(false);
            setIsVerifyingCode(true);
          }}
          user={user?.data}
        />
      )}
      {isVerifyingCode && (
        <UserVerifyCodeModal
          onCancel={() => setIsVerifyingCode(false)}
          onDone={() => setIsVerifyingCode(false)}
          user={user?.data}
        />
      )}
      {isResettingPassword && (
        <UserResetPasswordModal
          onCancel={() => setIsResettingPassword(false)}
          onDone={() => setIsResettingPassword(false)}
          user={user?.data}
        />
      )}
      {showData && (
        <UserDataModal user={user?.data} onCancel={() => setShowData(false)} />
      )}
      {isEndingSession && (
        <EndUserSessionModal
          user={user?.data}
          onCancel={() => setIsEndingSession(false)}
          onDone={() => {
            setIsEndingSession(false);
            logoutWithRedirect();
          }}
        />
      )}
    </>
  );
}
