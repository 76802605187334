import React from "react";
import PropTypes from "prop-types";
import Panel from "components/shared/panel/Panel";
import Info from "./info/Info";
import moment from "moment";

function BillingInfo({ user, vault }) {
  let storeTypeName = vault?.subscriptionV3?.storeType?.toLowerCase();
  let storeInfo = vault?.subscriptionV3?.[storeTypeName];
  let subscriptionPrice = storeInfo?.subscriptionPrice;
  let cost = (subscriptionPrice / 100)?.toFixed(2);
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  let planCost = !!subscriptionPrice ? formatter.format(cost) : "--";

  function formatSubscriptionStore(storeName) {
    return !!storeName
      ? storeName.charAt(0).toUpperCase() + storeName.toLowerCase().slice(1)
      : "--";
  }

  const userBillingInfo = [
    {
      label: "Account Type",
      value: formatSubscriptionStore(vault?.subscriptionV3?.storeType),
    },
    {
      label: `${formatSubscriptionStore(
        vault?.subscriptionV3?.storeType
      )} Account`,
      value: storeInfo?.paymentProvider?.link ?? "--",
      isFullCol: true,
      isLink: !!storeInfo?.paymentProvider?.link ? true : false,
    },
    {
      label: "Plan / Subscription ID",
      isFullCol: true,
      value: vault?.subscriptionV3?.subscriptionId ?? "--",
    },
    {
      label: "Promo Code",
      value: storeInfo?.promoCode ?? "--",
    },
    {
      label: "Plan Cost",
      value: planCost,
    },
    {
      label: "Last 4",
      value: storeInfo?.paymentMethod?.last4 ?? "--",
    },
    {
      label: "Previous Payment",
      value: storeInfo?.lastPayment?.date
        ? moment.unix(storeInfo?.lastPayment?.date).format("M/D/YYYY")
        : "--",
    },
    {
      label: "Next Payment",
      value: storeInfo?.nextPayment?.date
        ? moment.unix(storeInfo?.nextPayment?.date).format("M/D/YYYY")
        : "--",
    },
    {
      label: "Expiration Date",
      value: vault?.subscriptionV3?.subscriber?.subscription?.expiresDate
        ? moment
            .unix(vault?.subscriptionV3?.subscriber?.subscription?.expiresDate)
            .format("M/D/YYYY")
        : "--",
    },
    {
      label: "Billing ZipCode",
      value: storeInfo?.defaultPaymentBillingZip ?? "--",
    },
  ];

  return (
    <Panel hasPadding>
      <h3 className="mb-3">Billing Info</h3>
      <Info infoArray={userBillingInfo} />
    </Panel>
  );
}

BillingInfo.propTypes = {};

export default BillingInfo;
